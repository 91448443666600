import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  selectAll (e) {
    e.preventDefault()
    this.element
      .querySelectorAll("input[type='checkbox']")
      .forEach(checkbox => {
        checkbox.checked = true
      })
  }
}
