import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['hideable', 'trigger']

  showTargets () {
    this.hideableTargets.forEach(el => {
      el.classList.remove('hidden')
    })
  }

  hideTargets () {
    this.hideableTargets.forEach(el => {
      el.classList.add('hidden')
    })
  }

  removeTrigger () {
    this.triggerTarget.remove()
  }

  toggleTargets () {
    this.hideableTargets.forEach(el => {
      if (el.classList.contains('hidden')) {
        el.classList.remove('hidden')
      } else {
        el.classList.add('hidden')
      }
    })
  }
}
