import { Controller } from '@hotwired/stimulus'
import TomSelect from 'tom-select'

// NOTES:
// - `linkMenu` does this menu "jump" to a page on select? (option values should be URLs)
// - `params` represents the TomSelect configuration options and is OPTIONAL
export default class extends Controller {
  static values = {
    linkMenu: Boolean,
    params: Object,
    turbo: { type: Boolean, default: true }
  }

  connect () {
    const selectInput = this.element.querySelector('select')
    let tomSelectConfig = { ...this.paramsValue }

    this.select = new TomSelect(selectInput, tomSelectConfig)
    this.select.on('change', this._handleSelect.bind(this))
    this.select.on('dropdown_open', this._handleDropdownOpen.bind(this))
    this.select.on('dropdown_close', this._handleDropdownClose.bind(this))
  }

  disconnect () {
    if (this.select) this.select.destroy()
  }

  dispatchGaEventOnSelect (dropdownLink) {
    this.dispatch('userEvent', {
      prefix: 'analytics',
      detail: {
        handler: 'handleBasicGaEvent',
        data: {
          gaCategory: 'Combobox',
          gaAction: `Clicked ${dropdownLink}`,
          gaLabel: '@PATH@'
        }
      }
    })
  }

  _handleDropdownOpen () {
    this.element.querySelector('.ts-dropdown').style.opacity = 1
  }

  _handleDropdownClose () {
    this.element.querySelector('.ts-dropdown').style.opacity = 0
  }

  _handleSelect () {
    if (this.linkMenuValue === false) return
    const linkPath = this.select.getValue()
    this.dispatchGaEventOnSelect(linkPath)
    this._jumpTo(linkPath)
  }

  _jumpTo (url) {
    if (this.turboValue === true) {
      Turbo.visit(url)
    } else {
      window.location.href = url
    }
  }
}
