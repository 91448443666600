import { Application } from '@hotwired/stimulus'
import mrujs from 'mrujs'
import StimulusReflex from 'stimulus_reflex'
import consumer from '../channels/consumer'
import CableReady from 'cable_ready'

CableReady.initialize({ consumer })

CableReady.operations.turbolessNavigateTo = operation => {
  window.location.href = operation.url
}

const application = Application.start()

// Configure Stimulus development experience
application.warnings = true
application.debug = false
window.Stimulus = application

mrujs.start()

StimulusReflex.initialize(application, { isolate: true })

application.consumer = consumer

export { application }
