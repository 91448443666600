import { Controller } from '@hotwired/stimulus'
import { enter, leave } from 'el-transition'

// USAGE:
//   set `data-apparate-target="trigger"` on the triggering element
//
//   set `data-apparate-target="subject"` on the hidden element as long as its nested under the controller
//   ...OR...
//   set `data-apparate-subject-selector="<selector>"` to target ANY element(s) in the DOM
export default class extends Controller {
  static targets = ['trigger', 'subject']

  toggle () {
    this.targetElements().forEach(elem => {
      if (elem.classList.contains('hidden')) {
        enter(elem)
      } else {
        leave(elem)
      }
    })
  }

  appear () {
    this.targetElements().forEach(elem => {
      enter(elem)
    })
  }

  vanish (event) {
    this.targetElements().forEach(elem => {
      leave(elem)
    })
  }

  vanishOutsideClick (event) {
    const triggerClicked = this.triggerTarget.contains(event.target)

    if (!triggerClicked) {
      this.targetElements().forEach(elem => {
        leave(elem)
      })
    }
  }

  targetElements () {
    const subjectSelector = this.triggerTarget.dataset.apparateSubjectSelector
    if (typeof subjectSelector != 'undefined') {
      return document.querySelectorAll(subjectSelector)
    } else {
      return [this.subjectTarget]
    }
  }
}
