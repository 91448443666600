import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    target: String
  }

  scrollTo (event) {
    const offset = 10
    const target = document.querySelector(this.targetValue)
    const elementPosition =
      target.getBoundingClientRect().top + window.pageYOffset
    const offsetPosition = elementPosition - offset

    if (!event.currentTarget.closest('details').open) {
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      })
    }
  }
}
