import { Controller } from '@hotwired/stimulus'
import { enter, leave } from 'el-transition'
export default class extends Controller {
  connect () {
    document.addEventListener('mouseleave', function (event) {
      if (
        event.clientY <= 0 ||
        event.clientX <= 0 ||
        event.clientX >= window.innerWidth ||
        event.clientY >= window.innerHeight
      ) {
        for (const pop of document.querySelectorAll('[data-popdown]')) {
          pop.classList.add('hidden')
          pop.classList.remove('flex')
        }
      }
    })

    this.boundHideMobile = this.hideMobile.bind(this)
    document.addEventListener('mobile-nav:hide', this.boundHideMobile)
  }

  disconnect () {
    document.removeEventListener('mobile-nav:hide', this.boundHideMobile)
  }

  show (event) {
    const popdown = document.querySelector(event.target.dataset.popdownTarget)

    setTimeout(() => {
      // ensures that if they accidentally moused-over another menu link,
      // we ignore that if, after 100ms, they ended up hovering over the original popdown
      if (this.element.querySelectorAll('[data-popdown]:hover').length > 0) {
        return
      }

      const overClasses = [...document.querySelectorAll(':hover')]
        .map(el => el.className)
        .join(' ')

      if (
        !overClasses.includes('popdown-menu') &&
        !overClasses.includes('navbar-link')
      ) {
        // if, at the end of this timeout function, they're ultimately not hovering
        // over the popdown or the navbar link, hide all popdowns
        this.hideAllPopdowns()
      } else {
        // ok, show the popdown
        popdown.classList.remove('hidden')
        popdown.classList.add('flex')

        this.hideOtherPopdowns(popdown)
      }
    }, 100)
  }

  hide (event) {
    const hidingPopdown = event.target

    if (this.element.querySelectorAll('[data-popdown]:hover').length > 0) {
      return
    }

    if (this.element.querySelectorAll('a:hover').length > 0) {
      return
    }

    hidingPopdown.classList.add('hidden')
    hidingPopdown.classList.remove('flex')
  }

  hideAllPopdowns () {
    const popdowns = document.querySelectorAll('[data-popdown]')

    for (const pop of popdowns) {
      pop.classList.add('hidden')
      pop.classList.remove('flex')
    }
  }

  hideOtherPopdowns (except) {
    const popdowns = document.querySelectorAll('[data-popdown]')

    for (const pop of popdowns) {
      if (pop === except) continue

      pop.classList.add('hidden')
      pop.classList.remove('flex')
    }
  }

  toggleMobile () {
    const mobileMenu = this.element.querySelector('#mobile-menu')
    const button = this.element.querySelector('#mobile-nav-button')

    button.disabled = true

    if (mobileMenu.classList.contains('hidden')) {
      this.showMobile()
    } else {
      this.hideMobile()
    }
  }

  showMobile () {
    const mobileMenu = this.element.querySelector('#mobile-menu')
    const openIcon = this.element.querySelector('svg[data-icon-open]')
    const closeIcon = this.element.querySelector('svg[data-icon-close]')
    const button = this.element.querySelector('#mobile-nav-button')

    openIcon.classList.add('hidden')
    closeIcon.classList.remove('hidden')

    this.dispatch('search:close', { prefix: false })

    enter(mobileMenu).then(() => {
      button.disabled = false
    })
  }

  hideMobile () {
    const mobileMenu = this.element.querySelector('#mobile-menu')
    const openIcon = this.element.querySelector('svg[data-icon-open]')
    const closeIcon = this.element.querySelector('svg[data-icon-close]')
    const button = this.element.querySelector('#mobile-nav-button')

    openIcon.classList.remove('hidden')
    closeIcon.classList.add('hidden')

    leave(mobileMenu).then(() => {
      button.disabled = false
    })
  }
}
