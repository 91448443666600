import { Controller } from '@hotwired/stimulus'
import { useApplication, useIntersection } from 'stimulus-use'
import Cookies from 'js-cookie'
export default class extends Controller {
  static targets = ['button']
  static values = { url: String, markUrl: String, unmarkUrl: String, joinUrl: String }

  connect () {
    useApplication(this)
    useIntersection(this)
    this.element['activityButtonsController'] = this

    this.boundMarkedListener = this.handleMarked.bind(this)
    this.boundUnmarkedListener = this.handleUnmarked.bind(this)

    this.element.addEventListener(
      'activity-streams--button:marked',
      this.boundMarkedListener
    )
    this.element.addEventListener(
      'activity-streams--button:unmarked',
      this.boundUnmarkedListener
    )
  }

  disconnect () {
    this.element.removeEventListener(
      'activity-streams--button:marked',
      this.boundMarkedListener
    )
    this.element.removeEventListener(
      'activity-streams--button:unmarked',
      this.boundUnmarkedListener
    )
  }

  appear () {
    const userSignedIn = Cookies.get('user_signed_in') === 'true'

    if (!this.markedOnAppearance && userSignedIn) {
      this.markButtons()
      this.markedOnAppearance = true
    }
  }

  markButtons () {
    let resourceIds = []
    let resourceTypes = []

    this.buttonTargets.forEach(button => {
      resourceIds.push(button.activityStreamButton.resourceIdValue)
      resourceTypes.push(button.activityStreamButton.resourceTypeValue)
    })

    resourceIds = [...new Set(resourceIds)].join(',')
    resourceTypes = [...new Set(resourceTypes)].join(',')

    const urlParams = new URLSearchParams({
      ids: resourceIds,
      streamable_types: resourceTypes
    })

    const url = `${this.urlValue}?${urlParams.toString()}`
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json())
      .then(buttonMarks => {
        this.buttonTargets.forEach(button => {
          let markArr = buttonMarks[button.activityStreamButton.markIdValue]
          let kind = button.activityStreamButton.kindValue.replace(/-/g, '_')
          if (markArr && markArr.includes(kind)) {
            button.activityStreamButton.marked = true
          }
        })
      })
      .catch(error => {
        // NOOP: do nothing, since we don't know if we can mark anything
      })
  }

  handleMarked (event) {
    const userSignedIn = Cookies.get('user_signed_in') === 'true'
    if (userSignedIn) {
      this._toggleMarked(event, this.markUrlValue)
    } else {
      window.location.href = this.joinUrlValue
    }
  }
  
  handleUnmarked (event) {
    const userSignedIn = Cookies.get('user_signed_in') === 'true'
    if (userSignedIn) {
      this._toggleMarked(event, this.unmarkUrlValue)
    } else {
      window.location.href = this.joinUrlValue
    }
  } 

  _toggleMarked (event, url) {
    event.detail.source.activityStreamButton.disabled = true

    fetch(url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.csrfToken
      },
      body: JSON.stringify({
        sgid: event.detail.resourceSgid,
        kind: event.detail.kind
      })
    })
      .then(response => response.json())
      .then(data => {
        if (data['message'] && data['message'] === 'ok') {
          // it's optimistically marked, so we don't need to do much
          event.detail.source.activityStreamButton.disabled = false
        }
      })
      .catch(error => {
        event.detail.source.activityStreamButton.disabled = false
        event.detail.source.classList.remove('disabled')
      })
  }
}
