import { Controller } from '@hotwired/stimulus'

// This controller provides a simple, flexible way to build a set of filters on a page
// that hide/show targeted elements of a shared css selector

// USAGE:
//   `data-controller="element-filter"` on the parent
//   `data-filter-elements-selector=".general-class-for-all-elems-to-be-filtered"` on the parent
//
//   set `data-action="filter"` on the triggering elements
//   set `data-filter-class="specific-class"` on the triggering elements
//
//   set `data-filter-active-class` and `data-filter-inactive-class` on the triggering elements
export default class extends Controller {
  filter (event) {
    event.preventDefault()

    const elems = document.querySelectorAll(
      this.element.dataset.filterElementsSelector
    )
    const allButtons = this.element.querySelectorAll(
      "[data-action='element-filter#filter']"
    )
    const filterClass = event.srcElement.dataset.filterClass

    if (!filterClass) return

    allButtons.forEach(btn => {
      btn.disabled = true

      let activeClasses = btn.dataset.filterActiveClass.split(' ')
      let inactiveClasses = btn.dataset.filterInactiveClass.split(' ')

      if (btn === event.srcElement) {
        btn.classList.add(...activeClasses)
        btn.classList.remove(...inactiveClasses)
      } else {
        btn.classList.remove(...inactiveClasses)
        btn.classList.remove(...activeClasses)
      }
    })

    elems.forEach(el => {
      el.classList.remove('hidden')

      if (!el.classList.contains(filterClass)) el.classList.add('hidden')
    })

    allButtons.forEach(btn => {
      btn.disabled = false
    })
  }
}
