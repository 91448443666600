import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { url: String, shareableId: String, shareableType: String }

  recordShare (event) {
    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('Accept', 'application/html')
    myHeaders.append('X-CSRF-Token', Rails.csrfToken())

    const url = this.urlValue
    const formData = {
      id: this.shareableIdValue,
      type: this.shareableTypeValue
    }

    fetch(url, {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(formData)
    })
  }
}
