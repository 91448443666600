import { Controller } from '@hotwired/stimulus'
import Cookies from 'js-cookie'

export default class extends Controller {
  static values = {
    cookieName: String
  }

  dismiss () {
    Cookies.set(this.cookieNameValue, true, {
      expires: 60
    })
    this.element.remove()
  }

  followLink () {
    window.location = this.element.dataset.href
  }
}
