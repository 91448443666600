import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['poster', 'video']

  playVideo () {
    this.posterTarget.classList.add('hidden')
    this.videoTarget.classList.remove('hidden')
    this.videoTarget.play()
  }

  videoEnded () {
    this.posterTarget.classList.remove('hidden')
    this.videoTarget.classList.add('hidden')
  }
}
