import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    checked: String,
    unchecked: String
  }

  changed (event) {
    let checkbox = event.currentTarget

    checkbox.value = checkbox.checked ? this.checkedValue : this.uncheckedValue

    this.dispatch('toggled', {
      detail: {
        checked: checkbox.checked,
        source: this.element.querySelector('sl-checkbox')
      },
      prefix: 'fancy-checkbox'
    })
  }
}
