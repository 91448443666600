import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  clickSearchButton () {
    const openSearchButton = document.getElementById(
      'aon--navbar--open-search-button'
    )
    if (openSearchButton) openSearchButton.click()
  }
}
