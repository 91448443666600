import { Controller } from '@hotwired/stimulus'
import { useApplication, useIntersection } from 'stimulus-use'
import Cookies from 'js-cookie'

export default class extends Controller {
  static targets = ['button']
  static values = { url: String }

  connect () {
    useApplication(this)
    useIntersection(this)
    this.element['listButtonsController'] = this

    this.boundListener = this.listPopupComponentUpdated.bind(this)
    this.element.addEventListener(
      'aon--lists-popup-component:updated',
      this.boundListener
    )
  }

  disconnect () {
    this.element.removeEventListener(
      'aon--lists-popup-component:updated',
      this.boundListener
    )
  }

  listPopupComponentUpdated () {
    this.markButtons()
  }

  appear () {
    const userSignedIn = Cookies.get('user_signed_in') === 'true'

    if (userSignedIn && !this.markedOnAppearance) {
      this.markButtons()
      this.markedOnAppearance = true
    }
  }

  markButtons () {
    let listableIds = []
    let listableTypes = []

    this.buttonTargets.forEach(button => {
      listableIds.push(button.dataset.listButtonsListableId)
      listableTypes.push(button.dataset.listButtonsListableType)
    })

    listableIds = [...new Set(listableIds)].join(',')
    listableTypes = [...new Set(listableTypes)].join(',')

    const urlParams = new URLSearchParams({
      ids: listableIds,
      types: listableTypes
    })

    fetch(`${this.urlValue}?` + urlParams, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(r => r.json())
      .then(data => {
        // (re)mark buttons in the dataset from the server
        let ids = data.map(d => `lists-popup-button-${d}`)
        this.buttonTargets.forEach(button => {
          let btn = button.querySelector('.aon-toggle-button').toggleButton
          if (ids.includes(button.dataset?.listButtonId)) {
            btn.active = true
            btn.markClones(true)
          } else {
            btn.active = false
            btn.markClones(false)
          }
        })
      })
  }
}
