import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { page: String, section: String }

  tabShown (event) {
    this.dispatch('userEvent', {
      prefix: 'analytics',
      detail: {
        handler: 'handleBasicGaEvent',
        data: {
          gaAction: `Viewed ${this.sectionValue} Tab`,
          gaCategory: this.pageValue,
          gaLabel: event.detail.name
        }
      }
    })
  }
}
