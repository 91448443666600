import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['link']
  static values = { inactiveClass: String, activeClass: String }

  connect () {
    if (location.hash === '') {
      this._doMarkActive(this.linkTargets[0])
    } else {
      this._doMarkActive(
        this.element.querySelector(`a[href='${location.hash}']`).parentElement
      )
    }
  }

  markActive (event) {
    this.linkTargets.forEach(elem => {
      if (event.target === elem.querySelector('a')) {
        this._doMarkActive(elem)
      } else {
        this._doMarkInactive(elem)
      }
    })
  }

  _doMarkActive (elem) {
    elem.classList.remove(this.inactiveClassValue)
    elem.classList.add(this.activeClassValue)
  }

  _doMarkInactive (elem) {
    elem.classList.add(this.inactiveClassValue)
    elem.classList.remove(this.activeClassValue)
  }
}
