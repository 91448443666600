import { Controller } from '@hotwired/stimulus'
import Swiper from 'swiper'
import { Navigation } from 'swiper/modules'
import 'swiper/css'

export default class extends Controller {
  static targets = ['carousel', 'prevButton', 'nextButton', 'caption']

  swiper = null
  originalSlides = []
  originalSlidesCount = 0
  prefersReducedMotion = false
  mediaQuery = null

  static SLIDES_PER_GROUP = 1
  static SPACE_BETWEEN_SLIDES = 5

  connect () {
    this.prefersReducedMotion = window.matchMedia(
      '(prefers-reduced-motion: reduce)'
    ).matches
    this.mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)')
    this.mediaQuery.addEventListener(
      'change',
      this.handleReducedMotionChange.bind(this)
    )
    this.initSwiper()
  }

  disconnect () {
    if (this.swiper) {
      this.swiper.destroy()
    }
    this.mediaQuery.removeEventListener(
      'change',
      this.handleReducedMotionChange.bind(this)
    )
  }

  initSwiper () {
    this.setupOriginalSlides()
    this.createTripleSlideSet()
    this.initializeSwiperInstance()
  }

  setupOriginalSlides () {
    this.originalSlides = Array.from(
      this.carouselTarget.querySelectorAll('.swiper-slide')
    )
    this.originalSlidesCount = this.originalSlides.length
  }

  // Create three sets of slides for infinite scroll effect
  createTripleSlideSet () {
    const slides = [
      ...this.originalSlides.map(slide => slide.cloneNode(true)),
      ...this.originalSlides.map(slide => slide.cloneNode(true)),
      ...this.originalSlides.map(slide => slide.cloneNode(true))
    ]

    const wrapper = this.carouselTarget.querySelector('.swiper-wrapper')
    wrapper.innerHTML = ''
    slides.forEach((slide, _index) => {
      wrapper.appendChild(slide)
    })
  }

  getSwiperConfig () {
    return {
      init: false,
      modules: [Navigation],
      slidesPerView: 'auto',
      slidesPerGroup: this.constructor.SLIDES_PER_GROUP,
      spaceBetween: this.constructor.SPACE_BETWEEN_SLIDES,
      centeredSlides: false,
      slideToClickedSlide: false,
      autoHeight: false,
      speed: this.prefersReducedMotion ? 0 : 300,
      navigation: {
        prevEl: this.prevButtonTarget,
        nextEl: this.nextButtonTarget,
        disabledClass: 'disabled'
      },
      on: {
        afterInit: this.handleAfterInit.bind(this),
        slideChangeTransitionEnd: this.handleSlideChangeTransitionEnd.bind(this)
      }
    }
  }

  initializeSwiperInstance () {
    this.swiper = new Swiper(
      this.carouselTarget.querySelector('.swiper'),
      this.getSwiperConfig()
    )
    this.swiper.init()
  }

  handleAfterInit (swiper) {
    swiper.slideTo(this.originalSlidesCount, 0, false)
    this.updateCaption(swiper)
    this.carouselTarget.querySelector('.swiper').classList.remove('skeleton')
    this.carouselTarget
      .querySelectorAll('button.carousel-nav')
      .forEach(button => {
        button.classList.remove('invisible')
      })
  }

  broadcastSlideChange (swiper) {
    const currentSlide = swiper.slides[swiper.activeIndex]
    const currentIndex = parseInt(currentSlide.dataset.index, 10)

    this.dispatch('slideChange', {
      prefix: 'imageCarousel',
      detail: { slideIndex: currentIndex }
    })
  }

  handleSlideChangeTransitionEnd (swiper) {
    const currentSetIndex = Math.floor(
      swiper.activeIndex / this.originalSlidesCount
    )

    if (currentSetIndex === 2) {
      // Jump to middle set if we're in the last set
      swiper.slideTo(swiper.activeIndex - this.originalSlidesCount, 0, false)
    } else if (currentSetIndex === 0) {
      // Jump to middle set if we're in the first set
      swiper.slideTo(swiper.activeIndex + this.originalSlidesCount, 0, false)
    }

    this.updateCaption(swiper)

    this.broadcastSlideChange(swiper)
  }

  updateCaption (swiper) {
    const currentSlide = swiper.slides[swiper.activeIndex]

    if (currentSlide) {
      const captionTemplate = currentSlide.querySelector('template.caption')

      if (captionTemplate && this.captionTarget) {
        this.captionTarget.style.transition = 'none'
        this.captionTarget.style.opacity = '0'

        // Force browser reflow
        this.captionTarget.offsetHeight

        this.captionTarget.innerHTML = captionTemplate.innerHTML

        if (!this.prefersReducedMotion) {
          this.captionTarget.style.transition = 'opacity 0.3s ease-in'
        }
        this.captionTarget.style.opacity = '1'
      }
    }
  }

  handleImageClick (event) {
    event.stopPropagation()

    this.dispatch('imageClick', {
      prefix: 'imageCarousel',
      detail: {
        slideIndex: parseInt(
          event.currentTarget.closest('.swiper-slide').dataset.index,
          10
        )
      }
    })
  }

  handleReducedMotionChange (event) {
    this.prefersReducedMotion = event.matches
    if (this.swiper) {
      this.swiper.params.speed = this.prefersReducedMotion ? 0 : 300
    }
  }
}
