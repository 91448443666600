import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    opts: Object
  }

  connect () {
    import('@splidejs/splide').then(module => {
      this.splide = module.default
      this.initCarousel()
    })
  }

  disconnect () {
    this.carousel.destroy('completely')
  }

  initCarousel () {
    let defaults = {}

    this.carousel = new this.splide(this.element, {
      ...defaults,
      ...this.optsValue
    })
    this.carousel.mount()
  }
}
