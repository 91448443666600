import { Controller } from '@hotwired/stimulus'
import tippy from 'tippy.js'
export default class extends Controller {
  static targets = ['button', 'menu']

  connect () {
    this.menuTarget.classList.remove('hidden')

    tippy(this.buttonTarget, {
      theme: 'aon',
      trigger: 'click',
      placement: 'bottom-end',
      interactive: true,
      content: this.menuTarget,
      delay: [100, 100],
      allowHTML: true,
      zIndex: 500
    })
  }
}
