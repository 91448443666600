import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {}

  share (event) {
    event.preventDefault()

    if (typeof FB != 'undefined') {
      FB.ui(
        {
          method: 'share',
          href: window.location.href
        },
        function (response) {
          // TODO: do all the other modal, ask for social like stuff?
        }
      )
    }
  }
}
