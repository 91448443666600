import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static classes = ['active', 'inactive']
  static values = {
    active: Boolean,
    activeTooltip: String,
    inactiveTooltip: String,
    activeTitle: String,
    inactiveTitle: String
  }

  // this toggle button emits an event when it is clicked: `aon-toggle-button:toggled`
  // this event includes the new value of the active property in `event.detail.active`
  //
  // you can also set the active property of the toggle button by setting the `active` attribute
  // e.g. btn.active = true
  // e.g. btn.active = false
  //
  // you can also toggle the active property of the toggle button by calling the `toggle` method
  connect () {
    this.element['toggleButton'] = this
    this.refreshButton()
  }

  disconnect () {}

  refreshButton () {
    const tooltip = this.element.closest('[data-tooltip]')
    if (this.activeValue) {
      this._activateButton()
      if (tooltip) tooltip.content = this.activeTooltipValue
    } else {
      this._deactivateButton()
      if (tooltip) tooltip.content = this.inactiveTooltipValue
    }
  }

  _activateButton () {
    const btn = this.element

    btn.classList.add(...this.activeClasses)
    btn.classList.remove(...this.inactiveClasses)
    btn.setAttribute('aria-pressed', 'true')
    btn.querySelector('span.sr-only').textContent = this.activeTitleValue

    if (btn.querySelector('[data-icon-toggle]')) {
      btn.querySelector('[data-icon-off]').classList.replace('block', 'hidden')
      btn.querySelector('[data-icon-on]').classList.replace('hidden', 'block')
    }
  }

  _deactivateButton () {
    const btn = this.element

    btn.classList.add(...this.inactiveClasses)
    btn.classList.remove(...this.activeClasses)
    btn.setAttribute('aria-pressed', 'false')
    btn.querySelector('span.sr-only').textContent = this.inactiveTitleValue

    if (btn.querySelector('[data-icon-toggle]')) {
      btn.querySelector('[data-icon-off]').classList.replace('hidden', 'block')
      btn.querySelector('[data-icon-on]').classList.replace('block', 'hidden')
    }
  }

  clicked (event) {
    event.stopPropagation()
    event.preventDefault()

    if (!event.isTrusted) return

    this.dispatch('clicked', { detail: event, prefix: 'aon-toggle-button' })
  }

  toggle (event) {
    event.stopPropagation()
    event.preventDefault()

    if (!event.isTrusted) return

    const newValue = !this.activeValue
    this.activeValue = newValue

    this.dispatch('toggled', {
      detail: { active: this.activeValue },
      prefix: 'aon-toggle-button'
    })

    this.markClones(newValue)
  }

  markClones (value) {
    // mark all clones of this button the same, if applicable, but don't emit events
    const buttonId = this.element.dataset.toggleButtonId
    if (buttonId) {
      document
        .querySelectorAll(`[data-toggle-button-id="${buttonId}"]`)
        .forEach(btn => {
          btn['toggleButton'].active = value
        })
    }
  }

  set active (value) {
    let newValue = value.toString().toLowerCase()
    if (newValue === this.activeValue.toString().toLowerCase()) return

    this.activeValue = newValue === 'false' ? false : true
  }

  get active () {
    return this.activeValue
  }

  activeValueChanged () {
    this.refreshButton()
  }
}
