import { Controller } from '@hotwired/stimulus'
import { enter, leave } from 'el-transition'
import { useHotkeys } from 'stimulus-use/hotkeys'
export default class extends Controller {
  initialize () {
    this.element.addEventListener('turbo:frame-render', function (event) {
      let frame = event.target
      let resultsBackground = frame.closest('[data-search-results-background]')

      // hide the white background and shadow for empty results notification
      if (frame.querySelector("[data-search-results-empty='true']")) {
        resultsBackground.classList = resultsBackground.dataset.noResultsClass
      } else {
        resultsBackground.classList = resultsBackground.dataset.resultsClass
      }

      // when the search results frame re-renders, we use `enter()` to
      // give it a nice fade-in-and-slide-up animation if it isn't already visible
      if (frame.id == 'search-results') {
        this.querySelector('[data-static-links-panel]').classList.add('hidden')

        let resultsCanvas = frame.closest('[data-search-results-canvas]')

        if (resultsCanvas.classList.contains('hidden')) {
          enter(resultsCanvas)
        }
      }
    })
  }

  connect () {
    useHotkeys(this, {
      esc: [this.escapeHandler]
    })

    this.boundEscapeHandler = this.escapeHandler.bind(this)
    document.addEventListener('search:close', this.boundEscapeHandler)
  }

  disconnect () {
    document.removeEventListener('search:close', this.boundEscapeHandler)
  }

  closeOnEscape (event) {
    if (event.key === 'Escape') this.escapeHandler()
  }

  escapeHandler () {
    const closeButton = this.element.querySelector('[data-close-search-button]')
    if (closeButton) closeButton.click()
  }

  performSearch (event) {
    let query = event.target.value
    const staticLinksPanel = this.element.querySelector(
      '[data-static-links-panel]'
    )
    const searchResultsPanel = this.element.querySelector(
      '[data-search-results-panel]'
    )
    const searchResultsCanvas = this.element.querySelector(
      '[data-search-results-canvas]'
    )
    const searchResultsFrame = this.element.querySelector(
      '[data-search-results-frame]'
    )
    const resultsBackground = this.element.querySelector(
      '[data-search-results-background]'
    )

    const searchForm = this.element.querySelector('[data-search-form]')

    if (query === '') {
      searchForm.querySelector("button[type='submit']").disabled = true
      resultsBackground.classList = resultsBackground.dataset.resultsClass
      searchResultsCanvas.classList.add('hidden')
      staticLinksPanel.classList.remove('hidden')
      return
    } else if (query.length < 2) {
      searchForm.querySelector("button[type='submit']").disabled = true
      return
    }

    searchForm.querySelector("button[type='submit']").disabled = false

    let href = searchResultsPanel.dataset.searchResultsHref.replace(
      'q=query',
      new URLSearchParams(`q=${query}`).toString()
    )

    // setting the `src` on a turbo-frame will make it immediately initiate a request!
    searchResultsFrame.setAttribute('src', href)
  }

  showSearch (event) {
    const searchPanel = document.querySelector(
      event.currentTarget.dataset.searchPanel
    )

    document.querySelector('body').style.overflow = 'hidden'
    document.querySelector('#page-and-footer').style.display = 'none'

    this.dispatch('mobile-nav:hide', { prefix: false })

    document
      .querySelectorAll('[data-hide-on-search="true"]')
      .forEach(element => {
        element.style.display = 'none'
      })

    enter(searchPanel).then(() => {
      searchPanel.querySelector('[data-search-input]').value = ''
      searchPanel.querySelectorAll('[data-animate-me]').forEach(element => {
        enter(element)
      })
      searchPanel.querySelector('[data-search-input]').focus()
    })
  }

  hideSearch (event) {
    const searchPanel = document.querySelector(
      event.currentTarget.dataset.searchPanel
    )
    const staticLinksPanel = this.element.querySelector(
      '[data-static-links-panel]'
    )
    const searchResultsCanvas = this.element.querySelector(
      '[data-search-results-canvas]'
    )

    document.querySelector('body').style.overflow = 'auto'
    document.querySelector('#page-and-footer').style.display = ''

    document
      .querySelectorAll('[data-hide-on-search="true"]')
      .forEach(element => {
        element.style.display = ''
      })

    searchResultsCanvas.classList.add('hidden')
    staticLinksPanel.classList.remove('hidden')
    this.element.querySelector('[data-search-input]').value = ''

    leave(searchPanel).then(() => {
      searchPanel.querySelectorAll('[data-animate-me]').forEach(element => {
        leave(element)
      })
    })
  }
}
