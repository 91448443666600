import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  scrollToLink (event) {
    event.preventDefault()
    let target = document.querySelector(event.currentTarget.hash)
    // NOTE: Using javascript to smooth scroll since using the tailwind class
    // `smooth-scroll` causes issues with turbolinks
    target.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    })
  }
}
