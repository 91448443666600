import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    const headers = this.element.querySelectorAll("h2")
    headers.forEach((el) => {
      el.id = this.parameterize(el.textContent)
    });
  }

  parameterize(text) {
    return text.trim().toLowerCase().replace(/[^a-z0-9]+/g,'-').replace(/(^-|-$)/g,'');
  }
}
