import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    href: String,
    title: String,
    options: String
  }

  open (event) {
    event.preventDefault()
    window.open(this.hrefValue, this.titleValue, this.optionsValue)
  }
}
