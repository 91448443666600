import {Controller} from '@hotwired/stimulus'
import Cookies from 'js-cookie'

export default class extends Controller {
  static values = {
    cookieName: String
  }

  close () {
    Cookies.set(this.cookieNameValue, true, {
      expires: 10
    })
    this.element.remove()
  }
}