import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    mainOpts: Object,
    thumbnailOpts: Object
  }

  connect () {
    import('@splidejs/splide').then(module => {
      this.splide = module.default
      this.initGalleries()
    })
  }

  disconnect () {
    this.gallery.destroy('completely')
    this.thumbnailGallery.destroy('completely')
  }

  initGalleries () {
    let defaults = {}

    this.gallery = new this.splide('#main-gallery', {
      ...defaults,
      ...this.mainOptsValue
    })

    this.thumbnailGallery = new this.splide('#thumbnail-gallery', {
      ...defaults,
      ...this.thumbnailOptsValue
    })

    this.gallery.sync(this.thumbnailGallery)
    this.gallery.mount()
    this.thumbnailGallery.mount()
  }
}
