import { Controller } from '@hotwired/stimulus'
import { useApplication } from 'stimulus-use'

export default class extends Controller {
  static values = { resourceSgid: String }

  initialize () {
    useApplication(this, { overwriteDispatch: false })
  }

  connect () {}

  handleCheckboxToggled (event) {
    event.stopPropagation()
    event.preventDefault()

    const checked = event.detail.checked
    const listUpdateUrl = event.target
      .closest('[data-checkbox-list-update-url]')
      .dataset.checkboxListUpdateUrl.replace('CHECKED', checked)
    let listPopup = document.querySelector(
      `[data-aon--lists-popup-component-resource-sgid-value='${this.resourceSgidValue}']`
    )

    fetch(listUpdateUrl, {
      method: 'PATCH',
      headers: {
        'X-CSRF-Token': this.csrfToken,
        'Content-Type': 'application/json'
      }
    }).then(r => {
      if (r.status === 200) {
        listPopup.listsPopupController.handleUserListsUpdate()

        this.dispatch('userEvent', {
          prefix: 'analytics',
          detail: {
            handler: 'handleAtlasListAction',
            data: { marked: checked }
          }
        })
      }
    })
  }

  createNewList (event) {
    event.preventDefault()

    const form = event.target.closest('form')
    const titleInput = form.querySelector('input[type="text"]')
    let listPopup = document.querySelector(
      `[data-aon--lists-popup-component-resource-sgid-value='${this.resourceSgidValue}']`
    )

    if (titleInput.value.length > 0) {
      fetch(form.action, {
        method: 'POST',
        headers: {
          'X-CSRF-Token': this.csrfToken,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          list: {
            title: titleInput.value
          }
        })
      }).then(res => {
        if (res.status === 200) {
          listPopup.listsPopupController.handleUserListsUpdate()

          this.dispatch('userEvent', {
            prefix: 'analytics',
            detail: {
              handler: 'handleAtlasListCreateAction',
              data: {}
            }
          })
        } else {
          titleInput.setAttribute('aria-invalid', true)
          form.querySelector('.error-message').content = 'Something went wrong'
          form.querySelector('.error-message').classList.remove('invisible')
        }
      })
    } else {
      titleInput.setAttribute('aria-invalid', true)
      form.querySelector('.error-message').classList.remove('invisible')
    }
  }
}
