import { Controller } from '@hotwired/stimulus'
// import tippy from 'tippy.js'
import { useMutation } from 'stimulus-use'

export default class extends Controller {
  connect () {
    const source = this.element

    useMutation(this, {
      attributes: true,
      attributeFilter: ['data-tooltip']
    })

    tippy(source, {
      theme: 'aon',
      content: source.dataset.tooltip,
      delay: [500, 100],
      allowHTML: true,
      zIndex: 500,
      onShow (instance) {
        if (tippy.currentInput.isTouch) {
          setTimeout(() => {
            instance.hide()
          }, 4000)
        }
      }
    })
  }

  mutate (entries) {
    entries.forEach(mutation => {
      mutation.target._tippy.setProps({
        content: mutation.target.dataset.tooltip
      })
    })
  }
}
