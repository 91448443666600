import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {}

  share (event) {
    event.preventDefault()

    let text = this.element.dataset.shareText
    let url = window.location.href
    let href = `https://www.reddit.com/submit?title=${text}&url=${url}`

    window.open(href, '_blank')
  }
}
