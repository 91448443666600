import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    if (this.isLoaded) this.fadeIn()
  }

  fadeIn () {
    this.element.querySelector('img').classList.remove('opacity-0')
  }

  get isLoaded () {
    return this.element.querySelector('img').complete
  }
}
