import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {}

  switchTo (event) {
    let targetPanel = document.getElementById(event.params.id)

    const allPanels = document.querySelectorAll('[data-places-panel]')
    const allTriggers = document.querySelectorAll('[data-places-panel-trigger]')

    for (const panel of allPanels) {
      if (panel === targetPanel) {
        panel.classList.remove('hidden')
      } else {
        panel.classList.add('hidden')
      }
    }

    for (const trigger of allTriggers) {
      if (trigger === event.target) {
        trigger.classList.add(
          ...event.target.dataset.triggerActiveClass.split(' ')
        )
      } else {
        trigger.classList.remove(
          ...event.target.dataset.triggerActiveClass.split(' ')
        )
      }
    }
  }
}
