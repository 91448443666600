import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    fetch('/aon/csrf.json', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
      .then(response => response.json())
      .then(data => {
        this.setCsrfTokens(data.param, data.token)
        this.reEnableForms()
      })
  }

  setCsrfTokens (csrfName, csrfToken) {
    const metaTagName = document.querySelector('meta[name="csrf-param"]')
    const metaTagToken = document.querySelector('meta[name="csrf-token"]')
    const csrfInputs = document.querySelectorAll(`input[name="${csrfName}"]`)

    if (metaTagName) metaTagName.content = csrfName
    if (metaTagToken) metaTagToken.content = csrfToken
    if (csrfInputs.length > 0) {
      csrfInputs.forEach(input => {
        input.value = csrfToken
      })
    }
  }

  reEnableForms () {
    document.querySelector('body').removeAttribute('data-js-forms-disabled')
  }
}
